import React from 'react';
import NewLabel from './NewLabel';
import ContributionLabel from './ContributionLabel';

import AllowImg from '../assets/images/top/allow.png';
import TwitterIcon from '../assets/images/icon/logo01.png';
import FacebookIcon from '../assets/images/icon/logo02.png';
import InstaIcon from '../assets/images/icon/logo03.png';
import FilmarksIcon from '../assets/images/icon/logo04.png';

class MainContainerYT extends React.Component {
    render(){
        let SnsIconImg = "";
        if(this.props.type === "twitter"){
            SnsIconImg = `<img src=${TwitterIcon} alt="twitter" />`;
        }else if(this.props.type === "facebook"){
            SnsIconImg = `<img src=${FacebookIcon} alt="facebook" />`;
        }else if(this.props.type === "insta"){
            SnsIconImg = `<img src=${InstaIcon} alt="instagram" />`;
        }else if(this.props.type === "filmarks"){
            SnsIconImg = `<img src=${FilmarksIcon} alt="filmarks" />`;
        }

        let quote = "";
        if(this.props.quote !== "1"){
            quote = `”<span class="quotation" style="top: -16px; left: -16px;">“</span>`;
        }

        let labels;
        if((this.props.isNew || this.props.isContribute === "white" || this.props.isContribute === "black")){
            labels =
            <ul className="main-container__M-label">
                <NewLabel isNew={this.props.isNew} />
                <ContributionLabel isContribute={this.props.isContribute} />
            </ul>;
        }

        return(
            <div className="main-container__M grid-item fadein">
                <a href={this.props.YouTubeUrl} className="youtube" target="_blank" rel="noopener noreferrer">
                    <div className="main-container__M-img"><img src={`${process.env.PUBLIC_URL}/uploads/${this.props.img}`} alt="" /></div>
                </a>
                <a href={this.props.link} target="_blank" rel="noopener noreferrer">
                    {labels}
                    <div className="main-container__M-text">
                        <h3 className="comment" dangerouslySetInnerHTML={{__html: this.props.head +quote}} />
                        <p dangerouslySetInnerHTML={{__html: this.props.body}} />
                        <div className="main-container__M-icon">
                            <img src={`${process.env.PUBLIC_URL}/uploads/${this.props.icon}`} alt="" />
                            <div className="main-container__M-icon-hover"><img src={AllowImg} alt="" /></div>
                            <p className="insta"  dangerouslySetInnerHTML={{__html: this.props.writer + SnsIconImg}} />
                        </div>
                    </div>
                </a>
            </div>
        );
    }
}

export default MainContainerYT;