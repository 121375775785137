import React from 'react';
import imgLabel from '../assets/images/top/new.jpg';

class NewLabel extends React.Component {
    render(){
        if(this.props.isNew){
            return(
                <li className='first'><img src={imgLabel} alt="" width="48" /></li>
            )
        }else{
            return null;
        }
    }
}
export default NewLabel;