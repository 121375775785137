import React from 'react';
import ImgWhite from '../assets/images/top/comment_w.jpg';
import ImgBlack from '../assets/images/top/comment_b.jpg';

class ContributeLabel extends React.Component {
    render(){
        if(this.props.isContribute === "white"){
            return <li className='second'><img src={ImgWhite} alt="" width="84" /></li>
        }else if(this.props.isContribute === "black"){
            return <li className='second'><img src={ImgBlack} alt="" width="84" /></li>
        }else{
            return null;
        }
    }
}
export default ContributeLabel;