import React from 'react';

import MainImg from '../assets/images/top/main05.jpg';
import LogoImg from '../assets/images/top/main-logo.png';
import TheaterImg from '../assets/images/top/pc_theater.png';
import TheaterHoverImg from '../assets/images/top/pc_theater_hover.png';
import RoadImg from '../assets/images/top/roadshow.png';
import TheaterSpImg from '../assets/images/top/sp_theater.png';

class MainVisual extends React.Component {
    render() {
        return(
        <div>
            <div className="main-visual">
                <div className="main-visual__image">
                    <img src={MainImg} alt="" className="main-images" />
                </div>
                <h1 className="main-visual__logo">
                    <span>
                        <img src={LogoImg} alt="映画『コンプリシティ/優しい共犯』公式サイト" />
                        {/* <div className="main-visual__theater">
                            <a href="https://eigakan.org/theaterpage/schedule.php?t=complicity" target="_blank" rel="noopener noreferrer">
                                <img src={TheaterImg} alt="" className="pc_only pc_theater" />
                                <img src={TheaterHoverImg} alt="" className="pc_only hover-img" />
                            </a>
                            <div className="main-visual__theater-btn">
                                <a href="https://eigakan.org/theaterpage/schedule.php?t=complicity" target="_blank" rel="noopener noreferrer">その他の劇場情報はこちら</a>
                            </div>
                        </div> */}
                        <div className="main-visual__roadshow"><img src={RoadImg} alt="" className="road-images" /></div>
                    </span>
                </h1>
            </div>
            {/* <div className="sp_only theater">
                <div className="main-visual__theater">
                    <a href="https://eigakan.org/theaterpage/schedule.php?t=complicity" target="_blank" rel="noopener noreferrer">
                        <img src={TheaterSpImg} alt="" className="sp_only" />
                    </a>
                    <div className="main-visual__theater-btn">
                        <a href="https://eigakan.org/theaterpage/schedule.php?t=complicity" target="_blank" rel="noopener noreferrer">その他の劇場情報はこちら</a>
                    </div>
                </div>
            </div> */}
        </div>
        )
    }
}

export default MainVisual;