import React from 'react';

class TopButton extends React.Component {
    constructor(){
        super();
        this.state = {
            view: false
        }
    }
    clickHandler() {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }
    componentDidMount() {
        window.addEventListener('scroll', () => {
            const scroll = document.documentElement.scrollTop || document.body.scrollTop;
            if(scroll > 100){
                this.setState({view: true});
            }else{
                this.setState({view: false});
            }
        })
    }
    render() {
        let opac;
        if(this.state.view){
            opac = 1;
        }else{
            opac = 0;
        }
        return (
            <div className="top-btn" style={{display: "block", transition: ".5s", opacity: opac}} onClick={this.clickHandler}></div>
        );
    }
}

export default TopButton;