import React from 'react';

import CREATPS from '../assets/images/top/CREATPS.png';
import KLOCKWORX from '../assets/images/top/KLOCKWORX.png';

class Billing extends React.Component {
    render() {
        return (
            <div className="billing" id="billing">
                <ul className="main-cast big-char">
                    <li>ルー・ユーライ</li>
                    <li>藤竜也</li>
                </ul>
                <ul className="cast">
                    <li>赤坂沙世</li>
                    <li>松本紀保</li>
                    <li>バオ・リンユ</li>
                    <li>シェ・リ</li>
                    <li>ヨン・ジョン</li>
                    <li>塚原大助</li>
                    <li>浜谷康幸</li>
                    <li>石田佳央</li>
                    <li>堺小春</li>
                    <li className="slash">占部房子</li>
                </ul>
                <dl className="director">
                    <dt className="middle-mini">監督・脚本・編集</dt>
                    <dd className="big-char">：近浦啓</dd>
                </dl>
                <dl className="theme-song">
                    <dt className="middle-mini">主題歌：</dt>
                    <dd><span className="middle-char spbr">テレサ・テン</span></dd>
                    <dd className="song-name">
                        <span>「我只在乎ニィ（時の流れに身をまかせ）」
                        <span className="small-char uni">（ユニバーサル ミュージック/USMジャパン）</span></span>
                    </dd>
                </dl>
                <ul className="staff producer">
                    <li>
                        <dl className="producer-width">
                            <dt>エグゼクティブプロデューサー：</dt>
                            <dd>近浦啓</dd>
                            <dd>ルアン・ジンシン</dd>
                            <dd >ディン・ジェ</dd>
                        </dl>
                    </li>
                    <li>
                        <dl>
                            <dt>プロデューサー：</dt>
                            <dd>フー・ウェイ</dd>
                            <dd >近浦啓</dd>
                        </dl>
                    </li>
                    <li>
                        <dl>
                            <dt>共同プロデューサー：</dt>
                            <dd>ナイ・アン</dd>
                        </dl>
                    </li>
                    <li>
                        <dl>
                            <dt>アソシエイトプロデューサー：</dt>
                            <dd>三浦仁</dd>
                            <dd>堀池みほ</dd>
                        </dl>
                    </li>
                </ul>
                <ul className="staff">
                    <li>
                        <dl>
                            <dt>撮影：</dt>
                            <dd>山崎裕</dd>
                        </dl>
                    </li>
                    <li>
                        <dl>
                            <dt>照明：</dt>
                            <dd>山本浩資</dd>
                        </dl>
                    </li>
                    <li>
                        <dl>
                            <dt>録音：</dt>
                            <dd>弦巻裕（J.S.A）</dd>
                            <dd>リ・ラン</dd>
                        </dl>
                    </li>
                    <li>
                        <dl>
                            <dt>美術：</dt>
                            <dd>部谷京子</dd>
                        </dl>
                    </li>
                    <li>
                        <dl>
                            <dt>装飾：</dt>
                            <dd>柳澤武</dd>
                        </dl>
                    </li>
                    <li>
                        <dl>
                            <dt>スタイリスト：</dt>
                            <dd>宮本茉莉</dd>
                        </dl>
                    </li>
                    <li>
                        <dl>
                            <dt>ヘアメイク：</dt>
                            <dd>酒井夢月</dd>
                        </dl>
                    </li>
                    <li>
                        <dl>
                            <dt>音響効果：</dt>
                            <dd>伊東晃</dd>
                        </dl>
                    </li>
                    <li>
                        <dl>
                            <dt>助監督：</dt>
                            <dd>熊野桂太</dd>
                        </dl>
                    </li>
                    <li>
                        <dl>
                            <dt>スクリプター：</dt>
                            <dd>保坂栞</dd>
                        </dl>
                    </li>
                    <li>
                        <dl>
                            <dt>制作担当：</dt>
                            <dd>越智喜明</dd>
                        </dl>
                    </li>
                </ul>
                <ul className="others">
                    <li>
                        <dl>
                            <dt>製作：</dt>
                            <dd>クレイテプス</dd>
                            <dd className="light">Mystigri Pictures</dd>
                        </dl>

                    </li>
                    <li>
                        <dl>
                            <dt>特別協力：</dt>
                            <dd>大石田町</dd>
                        </dl>
                    </li>
                    <li>
                        <dl>
                            <dt>制作プロダクション：</dt>
                            <dd>クレイテプス</dd>
                            <dd style={{width: "36px"}}><a href="http://www.creatps.co.jp/" target="_blank" rel="noopener noreferrer"><img src={CREATPS} alt="CREATPS" width="70" /></a></dd>
                        </dl>
                    </li>
                    <li>
                        <dl>
                            <dt>配給：</dt>
                            <dd>クロックワークス</dd>
                            <dd style={{width: "36px"}}><a href="https://klockworx.com/" target="_blank" rel="noopener noreferrer"><img src={KLOCKWORX} alt="KLOCKWORX" width="36" /></a></dd>
                        </dl>
                    </li>
                </ul>
            </div>
        );
    }
}

export default Billing;