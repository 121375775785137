import React from 'react';

class Break extends React.Component {
    render() {
        return(
            <div className="break"></div>
        );
    }
}

export default Break;