import React from 'react';

import EventListener from 'react-event-listener';
import NewLabel from './NewLabel';
import ContributionLabel from './ContributionLabel';

import allow_w from '../assets/images/top/allow-w.png';
import TwitterIcon from '../assets/images/icon/logo01.png';
import FacebookIcon from '../assets/images/icon/logo02.png';
import InstaIcon from '../assets/images/icon/logo03.png';
import FilmarksIcon from '../assets/images/icon/logo04.png';

class MainContainerL extends React.Component {

    constructor(){
        super()
        this.state = {
            width: null
        }
    }
    componentDidMount() {
        this.widthCheck();
    }
    handleResize = () => {
        this.widthCheck();
    }

    widthCheck = () => {
        const w = window.innerWidth;
        const x = 768;
        if(w <= x){
            this.setState({ width: `${w}px` });
        }else{
            this.setState({ width: `640px` });
        }
    }
    render(){
        const BodyContainer = () => {
            if(this.props.body){
                return (
                    <p dangerouslySetInnerHTML={{__html: this.props.body}} />
                );
            }else{
                return null;
            }
        }
        let pcImage, spImage, iconImage;
        if(this.props.static === true){
            const mainImages = require.context('../assets/images/top', true);
            pcImage = mainImages('./' + this.props.pcImg);
            spImage = mainImages('./' + this.props.spImg);
            const iconImages = require.context('../assets/images/icon', true);
            iconImage = iconImages('./' + this.props.icon);
        }else{
            pcImage = `${process.env.PUBLIC_URL}/uploads/${this.props.pcImg}`;
            spImage = `${process.env.PUBLIC_URL}/uploads/${this.props.spImg}`;
            iconImage = `${process.env.PUBLIC_URL}/uploads/${this.props.icon}`;
        }

        let textPosition = this.props.textPosition;

        let SnsIconImg = "";
        let SnsClass = "";
        if(this.props.type === "twitter"){
            SnsIconImg = `<img src=${TwitterIcon} alt="twitter" />`;
            SnsClass = "twitter";
        }else if(this.props.type === "facebook"){
            SnsIconImg = `<img src=${FacebookIcon} alt="facebook" />`;
            SnsClass = "facebook";
        }else if(this.props.type === "insta"){
            SnsIconImg = `<img src=${InstaIcon} alt="instagram" />`;
            SnsClass = "insta";
        }else if(this.props.type === "filmarks"){
            SnsIconImg = `<img src=${FilmarksIcon} alt="filmarks" />`;
            SnsClass = "filmarks";
        }else if(this.props.type === "others" && this.props.snsIcon !== undefined){
            SnsIconImg = `<img src=${process.env.PUBLIC_URL}/uploads/${this.props.snsIcon} alt="SNSicon" />`;
            SnsClass = "twitter";
        }

        let quote;
        if(this.props.comment !== "1"){
            quote = `”<span class="quotation" style="top: -16px; left: -16px;">“</span>`;
        }
        
        let labels;
        if((this.props.isNew || this.props.isContribute === "white" || this.props.isContribute === "black")){
            labels =
            <ul className="main-container__L-label">
                <NewLabel isNew={this.props.isNew} />
                <ContributionLabel isContribute={this.props.isContribute} />
            </ul>;
        }

        return(
            <EventListener target="window" onResize={this.handleResize}>
                <div className="main-container__L" style={{height: this.state.width}}>
                    <img className="pc_only" src={pcImage} alt="" />
                    <img className="sp_only" src={spImage} alt="" />
                    <a href={this.props.link} target="_blank" rel="noopener noreferrer">
                        <div className={"main-container__L-inner " + textPosition}>
                            <div className={'main-container__' + this.props.size + '-text'}>
                                {labels}
                                <h3 className="comment" dangerouslySetInnerHTML={{__html: this.props.head + quote}} />
                                <BodyContainer />
                                <div className="main-container__L-icon">
                                    <img src={iconImage} alt="" />
                                    <div className="main-container__L-icon-hover"><img src={allow_w} alt="" /></div>
                                    <p className={SnsClass} dangerouslySetInnerHTML={{__html: this.props.writer + SnsIconImg}} />
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </EventListener>
        );
    }
}

export default MainContainerL;